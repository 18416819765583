import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { useCryptoContext } from "../context/CryptoContextCustom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CountdownTimer from "../CountDownTimer";
import QRImage from "../QRImage";
import { TEN_MINUTE, WALLET_STATUS } from "../../contants";
import ImageInput from "../ImageInput";
import { trim } from "lodash";

function BuySuccessDialog({
  total,
  currency,
  open,
  handleClose,
  handleCancel,
  image,
  setImage,
  onSubmit,
}) {
  const [loading, setLoading] = useState(false);
  const { bankAccountInfo } = useCryptoContext();

  const bankAccount = useMemo(
    () =>
      bankAccountInfo.find(
        (item) =>
          item.status === WALLET_STATUS.ACTIVE &&
          item.currency === trim(currency)
      ) ||
      bankAccountInfo?.[0] ||
      {},
    [bankAccountInfo, currency]
  );
  const { account, bank, account_name } = bankAccount;

  // const onUploadImage = async () => {
  //   try {
  //     await axios.put(`${API_ENDPOINT}/crypto_buy_usdt/${id}`, {
  //       image,
  //     });
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };
  const preText = currency === "USDT" ? "Wallet" : "Bank";
  return (
    <Dialog onClose={handleCancel} open={open}>
      <DialogTitle style={{ textAlign: "center", marginBottom: "12px" }}>
        <Typography style={{ fontSize: "22px", fontWeight: 600 }}>
          Please make a payment
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <Divider style={{ marginBottom: "24px" }} />
            <Typography
              style={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: 600,
                color: "gray",
              }}
            >
              Timer
            </Typography>
            <CountdownTimer
              targetTime={TEN_MINUTE}
              handleCancel={handleCancel}
            />
          </Grid>
          {currency === "USDT" && (
            <Grid xs={12}>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "24px",
                }}
              >
                <ImageInput onChangeImage={setImage} />
              </Box>
            </Grid>
          )}
          <Grid xs={12} sm={6}>
            <QRImage total={total} currency={currency} />
          </Grid>

          <Grid
            xs={12}
            sm={6}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Typography
              style={{
                fontWeight: 600,
              }}
            >{`Total payment: ${new Intl.NumberFormat().format(
              total
            )} ${currency}`}</Typography>

            {account && (
              <Typography>{`${preText} account: ${account} `}</Typography>
            )}
            {account_name && (
              <Typography>{`${preText} account owner: ${account_name}`}</Typography>
            )}
           {bank &&<Typography>{`${preText}: ${bank}`}</Typography> } 
            <CopyToClipboard text={account}>
              <Button
                size="small"
                style={{ marginTop: "24px" }}
                color="primary"
                variant="contained"
              >
                {`Copy ${preText} account`}
              </Button>
            </CopyToClipboard>
          </Grid>
        </Grid>

        <DialogActions
          style={{ marginTop: "16px", justifyContent: "space-between" }}
        >
          <Button
            size="large"
            variant="contained"
            onClick={handleCancel}
            color=""
          >
            Cancel
          </Button>
          <Button
            size="large"
            variant="contained"
            disabled={(currency === "USDT" && image === "") || loading}
            onClick={async () => {
              setLoading(true);
              await onSubmit();
              handleClose();
              setLoading(false);

              // if (image) {
              //   onUploadImage();
              // }
            }}
            color="secondary"
          >
            Submit
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default BuySuccessDialog;
