import { AppBar, Box, Tab, Tabs, makeStyles } from "@material-ui/core";
import React, { useMemo } from "react";
import BuyForm from "../components/Form/BuyForm";
import Layout from "../Layout";
import { useLocation } from "react-router-dom";
import DepositUSDT from "../components/Form/DepositUSDT";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundImage: "url(./banner2.jpg)",
  },
  root: {
    flexGrow: 1,
    margin: "auto",
    maxWidth: theme.breakpoints.values.md,

    // backgroundColor: theme.palette.background.paper,
  },
  tab: {
    color: "white",
    backgroundColor: "#14161a",
  },
  appbar: {
    backgroundColor: "#14161A",
  },
  content: {
    marginTop: "24px",
    borderRadius: "8px",
    backgroundColor: "white",
    padding: "16px",
    color: "black",
  },
}));
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={classes.content}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const modeMap = {
  buy: 0,
  // sell: 1,
  buyUSDT: 1,
};

function Form() {
  const classes = useStyles();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const mode = searchParams.get("mode");
  const [value, setValue] = React.useState(modeMap[mode]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Layout>
      <div className={classes.wrapper}>
        <div className={classes.root}>
          <AppBar position="static" className={classes.appbar}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab className={classes.tab} label="BUY USDT" {...a11yProps(0)} />
              {/* <Tab
                className={classes.tab}
                label="WITHDRAW GO MARKET"
                {...a11yProps(1)}
              /> */}
              <Tab
                className={classes.tab}
                label="DEPOSIT BROKER"
                {...a11yProps(1)}
              />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <BuyForm />
          </TabPanel>
          {/* <TabPanel value={value} index={1}>
            <SellForm />
          </TabPanel> */}
          <TabPanel value={value} index={1}>
            <DepositUSDT />
          </TabPanel>
        </div>
      </div>
    </Layout>
  );
}

export default Form;
