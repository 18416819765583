import React, { useMemo } from "react";
import { useCryptoContext } from "./context/CryptoContextCustom";
import { makeStyles } from "@material-ui/core";
import { WALLET_STATUS } from "../contants";

const useStyles = makeStyles((theme) => ({
  qrImage: {
    width: "100%",
    maxWidth: "300px",
    height: "300px",
    objectFit: "contain",
  },
}));
function QRImage({ total, currency }) {
  const clasess = useStyles();
  const { bankAccountInfo } = useCryptoContext();
  const bankAccount = useMemo(
    () =>
      bankAccountInfo.find(
        (item) =>
          item.status === WALLET_STATUS.ACTIVE && item.currency === currency
      ) ||
      bankAccountInfo?.[0] ||
      {},
    [bankAccountInfo, currency]
  );
  const { qr_code } = bankAccount;

  // const qrImage = useMemo(() => {
  //   return `https://img.vietqr.io/image/${bank}-${account}-compact2.jpg?amount=${total}&addInfo=${transfer_info}&accountName=${account_name}`;
  // }, [account, account_name, bank, total, transfer_info]);

  return <img className={clasess.qrImage} alt="qr code" src={qr_code} />;
}

export default QRImage;
