export const CURRENCY = {
  USD: "USD",
  REIL: "REIL",
  PESO: "PESO",
  USDT: "USDT",

};

export const API_ENDPOINT = "https://heroku.goappscript.com/crypto";

export const TEN_MINUTE = 10;

export const EXTEND_COST = 1 / 100;
export const EXTEND_COS_USDT = 1.5 / 100;

export const WALLET_STATUS = {
  ACTIVE: "Active",
  STOP: "Stop",
};


export const CURRENCY_MAP  = {

}