import React from "react";
import { useCryptoContext } from "./components/context/CryptoContextCustom";
import LoadingOverLay from "./components/LoadingOverLay";

function Layout(props) {
  const { loading } = useCryptoContext();
  return loading ? <LoadingOverLay loading={loading} /> : <>{props.children}</>;
}

export default Layout;
