import { ThemeProvider, makeStyles } from "@material-ui/core";
import Homepage from "./Pages/HomePage";
import "./App.css";
import { BrowserRouter, Route } from "react-router-dom";
import CoinPage from "./Pages/CoinPage";
import Header from "./components/Header";
import Form from "./Pages/Form";
import theme from "./theme";
import CryptoContextProvider from "./components/context/CryptoContextCustom";
import { SnackbarProvider } from "notistack";
import Contact from "./Pages/Contact";

const useStyles = makeStyles(() => ({
  App: {
    backgroundColor: "#14161a",
    color: "white",
    minHeight: "100vh",
  },
}));

function App() {
  const classes = useStyles();

  return (
    <SnackbarProvider autoHideDuration={2000}>
      <CryptoContextProvider>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <div className={classes.App}>
              <Header />
              <Route path="/" component={Homepage} exact />
              <Route path="/coins/:id" component={CoinPage} exact />
              <Route path="/form/" component={Form} exact />
              <Route path="/banking/" component={Form} exact />
              <Route path="/contact/" component={Contact} exact />
            </div>
          </ThemeProvider>
        </BrowserRouter>
      </CryptoContextProvider>
    </SnackbarProvider>
  );
}

export default App;
