import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { NumericFormat } from "react-number-format";

import * as yup from "yup";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import {
  API_ENDPOINT,
  CURRENCY,
  EXTEND_COST,
  WALLET_STATUS,
} from "../../contants";
import { useCryptoContext } from "../context/CryptoContextCustom";

import axios from "axios";
import { useSnackbar } from "notistack";
import BuySuccessDialog from "./BuySuccessDialog";
import { trim } from "lodash";

const useStyles = makeStyles((theme) => ({
  radioGroup: {
    display: "flex",
    flexDirection: "row",
    gap: "24px",
  },
  radioNetWorkGroup: {
    marginTop: "12px",
    marginLeft: "20px",
  },
}));

const validationSchema = yup.object().shape({
  amount_currency: yup.string().required("Input buy amount"),
  amount_currency_usdt: yup.string().required("Input buy amount"),
  wallet_address_receiver: yup.string().required("Please input wallet address"),
  name: yup.string().required("Please input reciever name"),
  phone: yup.string().required("Please input phone number"),
});

const BuyForm = () => {
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { exchangeRates, usdtPrice, extendCost, bankAccountInfo } =
    useCryptoContext();

  const [currentCost, setCurrentCost] = useState(EXTEND_COST);
  const usdPrice = usdtPrice.priceUsd;
  const {
    register,
    watch,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      currency: "USD",
      network: "TRC20"
    },
  });
  const watchCurrency = watch("currency");
  const watchCurrencyAmount = watch("amount_currency");
  const watchCurrencyAmountUSDT = watch("amount_currency_usdt");
  const [currencyExchange, setCurrencyExchange] = useState(exchangeRates.USD);
  const total = useMemo(() => {
    const amount = parseFloat(watchCurrencyAmount);
    if (isNaN(amount)) {
      return watchCurrencyAmountUSDT || 0;
    }

    return amount;
  }, [watchCurrencyAmount, watchCurrencyAmountUSDT]);

  const onNext = () => {
    setOpen(true);
  };

  const bankAccount = useMemo(
    () =>
      bankAccountInfo.find(
        (item) =>
          item.status === WALLET_STATUS.ACTIVE &&
          item.currency === trim(watchCurrency)
      ) ||
      bankAccountInfo?.[0] ||
      {},
    [bankAccountInfo, watchCurrency]
  );
  const { account, bank, account_name } = bankAccount;
  const onSubmit = async () => {
    try {
      const data = getValues();

      const res = await axios.post(`${API_ENDPOINT}/crypto_buy`, {
        ...data,
        account,
        bank,
        account_name,
        amount: watchCurrencyAmountUSDT,
        total_payment: total,
      });
      enqueueSnackbar("Information is sent successfully", {
        variant: "success",
      });
      setOpen(false);
    } catch (error) {
      enqueueSnackbar("Information can not send", {
        variant: "error",
      });
      console.log("error", error);
    }
    // Access form data here
  };
  useEffect(() => {
    setValue("amount_currency", "");
    setValue("amount_currency_usdt", "");
    setCurrencyExchange(exchangeRates[watchCurrency]);
    setCurrentCost(
      extendCost.find((item) => item.currency === watchCurrency)?.buy_fee
        ? extendCost.find((item) => item.currency === watchCurrency).buy_fee /
            100
        : 1 / 100
    );
  }, [exchangeRates, extendCost, watchCurrency]);

  const handleClose = () => {
    reset();
    enqueueSnackbar(
      "Please wait for admin to check or you can check your amount",
      {
        variant: "success",
        autoHideDuration: 5000,
      }
    );
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
    reset();
  };
  const defaultPrice =
    watchCurrency !== "USDT" || currencyExchange
      ? 1 *
        parseFloat(currencyExchange.replace(",", ".")) *
        (parseFloat(usdPrice) + parseFloat(usdPrice) * currentCost)
      : 1;
  return (
    <>
      <BuySuccessDialog
        open={open}
        handleClose={handleClose}
        handleCancel={handleCancel}
        total={total}
        currency={watchCurrency}
        onSubmit={onSubmit}
      />
      <form onSubmit={handleSubmit(onNext)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              rules={{ required: true }}
              control={control}
              name="currency"
              render={({ field }) => (
                <RadioGroup className={classes.radioGroup} {...field}>
                  <FormControlLabel
                    // {...register("currency")}
                    value="USD"
                    control={<Radio />}
                    label="USD"
                  />

                  <FormControlLabel
                    // {...register("currency")}
                    value="REIL"
                    control={<Radio />}
                    label="REIL"
                  />

                  <FormControlLabel
                    // {...register("currency")}
                    value="PESO"
                    control={<Radio />}
                    label="PESO"
                  />
                </RadioGroup>
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              style={{ color: "#860002", fontWeight: 600 }}
            >
              {`1 USDT = ${parseFloat(defaultPrice).toFixed(
                2
              )} ${watchCurrency}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">BUY_USDT</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <NumericFormat
              customInput={TextField}
              thousandSeparator={true}
              value={watchCurrencyAmount}
              {...register("amount_currency")}
              variant="outlined"
              fullWidth
              error={!!errors.amount_currency}
              helperText={errors.amount_currency?.message}
              onChange={(e) => {
                let inputValue = e.target.value.replace(/[^0-9.]/g, "");
                if (inputValue) {
                  const usd =
                    parseFloat(inputValue) /
                    parseFloat(currencyExchange.replace(",", ".")) /
                    (parseFloat(usdPrice) + parseFloat(usdPrice) * currentCost);
                  setValue("amount_currency", inputValue);
                  setValue("amount_currency_usdt", usd.toFixed(2));
                } else {
                  setValue("amount_currency_usdt", "");
                  setValue("amount_currency", "");
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {CURRENCY[watchCurrency]}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <NumericFormat
              customInput={TextField}
              value={watchCurrencyAmountUSDT}
              thousandSeparator={true}
              {...register("amount_currency_usdt")}
              fullWidth
              variant="outlined"
              error={!!errors.amount_currency_usdt}
              helperText={errors.amount_currency_usdt?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">USDT</InputAdornment>
                ),
              }}
              onChange={(e) => {
                let inputValue = e.target.value.replace(/[^0-9.]/g, "");
                if (inputValue) {
                  const ammout =
                    parseFloat(inputValue) *
                    parseFloat(currencyExchange?.replace(",", ".")) *
                    (parseFloat(usdPrice) + parseFloat(usdPrice) * currentCost);
                  setValue("amount_currency_usdt", inputValue);
                  setValue("amount_currency", ammout.toFixed(2));
                } else {
                  setValue("amount_currency_usdt", "");
                  setValue("amount_currency", "");
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider style={{ marginTop: "12px" }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Wallet address information</Typography>

            <TextField
              {...register("wallet_address_receiver")}
              placeholder="Wallet address"
              variant="outlined"
              fullWidth
              error={!!errors.wallet_address_receiver}
              helperText={errors.wallet_address_receiver?.message}
            />
          </Grid>
          <Grid>
            <Controller
              rules={{ required: true }}
              control={control}
              name="network"
              render={({ field }) => (
                <RadioGroup className={classes.radioNetWorkGroup} {...field}>
                  <FormControlLabel
                    // {...register("currency")}
                    value="TRC20"
                    control={<Radio />}
                    label="TRC20 (Tron - TRX)"
                  />
                  <FormControlLabel
                    // {...register("currency")}
                    value="ERC20"
                    control={<Radio />}
                    label="ERC20 (Etherum - ETH)"
                  />

                  <FormControlLabel
                    // {...register("currency")}
                    value="BEP20"
                    control={<Radio />}
                    label="BEP20 (BNB Smart chain -BSC)"
                  />
                </RadioGroup>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider style={{ marginTop: "12px" }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Information</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register("name")}
              variant="outlined"
              placeholder="Your name"
              fullWidth
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register("phone")}
              variant="outlined"
              placeholder="Phone Number"
              fullWidth
              error={!!errors.phone}
              helperText={errors.phone?.message}
            />
          </Grid>
          {/* <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <TotalTable total={total} currency={watchCurrency} amount={watchCurrencyAmount}/>
          </Grid> */}
          <Grid item xs={12}></Grid>

          {/* Add more fields as needed */}
          <Box mt={3} width="100%" display="flex" justifyContent="flex-end">
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Next"
              )}
            </Button>
          </Box>
        </Grid>
      </form>
    </>
  );
};

export default BuyForm;
