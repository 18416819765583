import { Box, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";

function CountdownTimer({ targetTime, handleCancel }) {
  const { enqueueSnackbar } = useSnackbar();
  const [remainingTime, setRemainingTime] = useState(targetTime * 60);
  // Update the remaining time every second
  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime((prevTime) =>
        prevTime === 0 ? prevTime : prevTime - 1
      );
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      handleCancel();
      enqueueSnackbar("Transaction time out", { variant: "error" });
    }, targetTime * 60 * 1000);
  }, []);

  // Format the minutes and seconds for display
  function formatTimeValue(value) {
    return value.toString().padStart(2, "0");
  }

  // Calculate minutes and seconds from remaining time
  const minutes = Math.floor(remainingTime / 60);
  const seconds = remainingTime % 60;

  return (
    <Box>
      <Typography
        style={{
          textAlign: "center",
          fontSize: "16px",
          fontWeight: 600,
          color: "gray",
        }}
      >
        {" "}
        Time for transaction
      </Typography>
      <Box
        style={{
          border: "1px solid black",
          borderRadius: "8px",
          fontSize: "16px",
          fontWeight: "600",
          padding: "14px 20px",
          textAlign: "center",
        }}
      >
        {`${formatTimeValue(minutes)}:${formatTimeValue(seconds)}`}
      </Box>
    </Box>
  );
}

export default CountdownTimer;
