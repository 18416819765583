import React, { useState } from "react";
import { Box, Button, CircularProgress, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import axios from "axios";
import { API_ENDPOINT } from "../contants";
const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
}));

function ImageInput({ onChangeImage }) {
  const classes = useStyles();
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleImageChange = async (event) => {
    try {
      const file = event.target.files[0];
      setLoading(true);
      setSelectedImage(URL.createObjectURL(file));
      var form = new FormData();
      form.append("image", file);

      const res = await axios.post(`${API_ENDPOINT}/upload`, form, {
        headers: {
          "content-type": "multipart/form-data", // do not forget this
        },
      });
      onChangeImage(res.data.filePath);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  if (loading) {
    return (
      <Box mt={2} display="flex" justifyContent="center">
        <CircularProgress></CircularProgress>
      </Box>
    );
  }

  return (
    <Box mt={2}>
      <input
        accept="image/*"
        className={classes.input}
        id="image-input"
        type="file"
        onChange={handleImageChange}
      />
      <label htmlFor="image-input">
        <Button
          size="small"
          variant="contained"
          color="secondary"
          component="span"
        >
          Please upload Transaction image
        </Button>
        {!selectedImage && (
          <Box
            style={{
              marginTop: "4px",
              border: "1px dashed grey",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "4px",
              cursor: "pointer",
            }}
          >
            <AddIcon />
          </Box>
        )}
      </label>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {selectedImage && (
          <img
            src={selectedImage}
            alt="Selected"
            style={{
              objectFit: "contain",
              marginTop: "1rem",
              maxWidth: "100%",
              maxHeight: "300px",
            }}
          />
        )}
      </Box>
    </Box>
  );
}

export default ImageInput;
