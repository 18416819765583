import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import {
  API_ENDPOINT,
  CURRENCY,
  EXTEND_COS_USDT,
  WALLET_STATUS,
} from "../../contants";
import { useCryptoContext } from "../context/CryptoContextCustom";
import { NumericFormat } from "react-number-format";

import { useSnackbar } from "notistack";
import BuySuccessDialog from "./BuySuccessDialog";
import axios from "axios";
import { trim } from "lodash";

const useStyles = makeStyles((theme) => ({
  radioGroup: {
    display: "flex",
    flexDirection: "row",
    gap: "24px",
  },
  radioNetWorkGroup: {
    marginTop: "12px",
    marginLeft: "20px",
  },
}));

const validationSchema = yup.object().shape({
  amount_currency: yup.string().required("Input buy amount"),
  amount_currency_usdt: yup.string().required("Input buy amount"),
  mt4_mt5: yup.string().required("Please input wallet address"),
  name: yup.string().required("Please input reciever name"),
  // phone: yup.string().required("Please input phone number"),
});

const DepositUSDT = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [id, setId] = useState("");
  const [image, setImage] = useState("");

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { exchangeRates, usdtPrice, extendCost, bankAccountInfo } =
    useCryptoContext();
  const usdPrice = usdtPrice.priceUsd;
  const {
    register,
    watch,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      currency: "USD",
    },
  });
  const watchCurrency = watch("currency");
  const watchCurrencyAmount = watch("amount_currency");
  const watchCurrencyAmountUSDT = watch("amount_currency_usdt");
  const [currentCost, setCurrentCost] = useState(EXTEND_COS_USDT);
  const [currencyExchange, setCurrencyExchange] = useState(exchangeRates.USD);
  const total = useMemo(() => {
    const amount = parseFloat(watchCurrencyAmount);
    if (isNaN(amount)) {
      return watchCurrencyAmountUSDT || 0;
    }

    return amount;
  }, [watchCurrencyAmount, watchCurrencyAmountUSDT]);

  const bankAccount = useMemo(
    () =>
      bankAccountInfo.find(
        (item) =>
          item.status === WALLET_STATUS.ACTIVE &&
          item.currency === trim(watchCurrency)
      ) ||
      bankAccountInfo?.[0] ||
      {},
    [bankAccountInfo, watchCurrency]
  );
  const { account, bank, account_name } = bankAccount;
  const onNext = () => {
    setOpen(true);
  };
  const onSubmit = async () => {
    const data = getValues();
    try {
      const res = await axios.post(`${API_ENDPOINT}/crypto_buy_usdt`, {
        ...data,
        account,
        bank,
        account_name,
        image,
        amount: watchCurrencyAmountUSDT,
        total_payment: total ? total : watchCurrencyAmountUSDT,
      });
      // const idTransaction = res.data?.data?.id || "";
      // if (image) {
      //   await axios.put(`${API_ENDPOINT}/crypto_buy_usdt/${idTransaction}`, {
      //     image,
      //   });
      // }

      enqueueSnackbar("Information is sent successfully", {
        variant: "success",
      });
      // setId(idTransaction);
      setOpen(false);
    } catch (error) {
      enqueueSnackbar("Information can not send", {
        variant: "error",
      });
      console.log("error", error);
    }
    // Access form data here
  };
  useEffect(() => {
    setValue("amount_currency", "");
    setValue("amount_currency_usdt", "");
    setCurrencyExchange(exchangeRates[watchCurrency]);
    setCurrentCost(
      extendCost.find((item) => item.currency === watchCurrency)?.deposit_fee
        ? extendCost.find((item) => item.currency === watchCurrency)
            .deposit_fee / 100
        : 1 / 100
    );
  }, [exchangeRates, extendCost, setValue, watchCurrency]);

  const handleClose = () => {
    reset();
    enqueueSnackbar(
      "Please wait for admin to check or you can check your amount",
      {
        variant: "success",
        autoHideDuration: 5000,
      }
    );
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
    reset();
  };

  const defaultPrice =
    watchCurrency !== "USDT" || currencyExchange
      ? parseFloat(1) *
        parseFloat(currencyExchange?.replace(",", ".")) *
        (parseFloat(usdPrice) + parseFloat(usdPrice) * currentCost)
      : 1;

  return (
    <>
      <BuySuccessDialog
        open={open}
        id={id}
        handleClose={handleClose}
        handleCancel={handleCancel}
        total={total}
        currency={watchCurrency}
        image={image}
        setImage={setImage}
        onSubmit={onSubmit}
      />
      <form onSubmit={handleSubmit(onNext)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              rules={{ required: true }}
              control={control}
              name="currency"
              render={({ field }) => (
                <RadioGroup className={classes.radioGroup} {...field}>
                  <FormControlLabel
                    // {...register("currency")}
                    value="USD"
                    control={<Radio />}
                    label="USD"
                  />

                  <FormControlLabel
                    // {...register("currency")}
                    value="REIL"
                    control={<Radio />}
                    label="REIL"
                  />

                  <FormControlLabel
                    // {...register("currency")}
                    value="PESO"
                    control={<Radio />}
                    label="PESO"
                  />
                  <FormControlLabel
                    // {...register("currency")}
                    value="USDT"
                    control={<Radio />}
                    label="USDT"
                  />
                </RadioGroup>
              )}
            />
          </Grid>
          {watchCurrency !== "USDT" && (
            <Grid
              item
              xs={12}
              sm={6}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                style={{ color: "#860002", fontWeight: 600 }}
              >
                {`1 USDT = ${parseFloat(defaultPrice).toFixed(
                  2
                )} ${watchCurrency}`}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="h6">DEPOSIT AMOUNT</Typography>
          </Grid>

          {watchCurrency !== "USDT" && (
            <Grid item xs={12} sm={6}>
              <NumericFormat
                customInput={TextField}
                thousandSeparator={true}
                value={watchCurrencyAmount}
                {...register("amount_currency")}
                variant="outlined"
                fullWidth
                error={!!errors.amount_currency}
                helperText={errors.amount_currency?.message}
                onChange={(e) => {
                  let inputValue = e.target.value.replace(/[^0-9.]/g, "");
                  if (inputValue) {
                    const usd =
                      parseFloat(inputValue) /
                      parseFloat(currencyExchange.replace(",", ".")) /
                      (parseFloat(usdPrice) +
                        parseFloat(usdPrice) * currentCost);
                    setValue("amount_currency", inputValue);
                    setValue("amount_currency_usdt", usd.toFixed(2));
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {CURRENCY[watchCurrency]}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <NumericFormat
              customInput={TextField}
              thousandSeparator={true}
              value={watchCurrencyAmountUSDT}
              {...register("amount_currency_usdt")}
              fullWidth
              variant="outlined"
              error={!!errors.amount_currency_usdt}
              helperText={errors.amount_currency_usdt?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">USDT</InputAdornment>
                ),
              }}
              onChange={(e) => {
                let inputValue = e.target.value.replace(/[^0-9.]/g, "");
                if (inputValue) {
                  const ammout =
                    parseFloat(inputValue) *
                    parseFloat(currencyExchange?.replace(",", ".")) *
                    (parseFloat(usdPrice) + parseFloat(usdPrice) * currentCost);

                  setValue("amount_currency_usdt", inputValue);
                  setValue("amount_currency", ammout.toFixed(2));
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider style={{ marginTop: "12px" }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Information</Typography>

            <TextField
              {...register("mt4_mt5")}
              placeholder="MT4, MT5"
              variant="outlined"
              fullWidth
              error={!!errors.mt4_mt5}
              helperText={errors.mt4_mt5?.message}
            />
          </Grid>

          {watchCurrency === "USDT" && (
            <Grid item xs={12}>
              <TextField
                {...register("wallet_address_trc20")}
                variant="outlined"
                placeholder="Wallet address TRC20"
                fullWidth
                error={!!errors.wallet_address_trc20}
                helperText={errors.wallet_address_trc20?.message}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              {...register("name")}
              variant="outlined"
              placeholder="Your name"
              fullWidth
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register("phone")}
              variant="outlined"
              placeholder="Phone Number"
              fullWidth
              error={!!errors.phone}
              helperText={errors.phone?.message}
            />
          </Grid>
          {/* <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <TotalTable
              total={total}
              currency={watchCurrency}
              amount={watchCurrencyAmount}
            />
          </Grid> */}
          <Grid item xs={12}></Grid>

          {/* Add more fields as needed */}
          <Box mt={3} width="100%" display="flex" justifyContent="flex-end">
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Next"
              )}
            </Button>
          </Box>
        </Grid>
      </form>
    </>
  );
};

export default DepositUSDT;
