import { Box, Typography, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundImage: "url(./banner2.jpg)",
    height: "100vh",
  },
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // margin: "auto",
    maxWidth: theme.breakpoints.values.md,

    // backgroundColor: theme.palette.background.paper,
  },
}));
function Contact() {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        <Box style={{ margin: "auto" }}>
          <Typography
            style={{ marginTop: "32px", fontWeight: 600 }}
            variant="h4"
          >
            Contact Information
          </Typography>

          <Typography style={{ marginTop: "32px" }} variant="h5">
            Support:{" "}
            <span>
              <a href="tel:+855978878787">+855 978 87 87 87</a>
            </span>
          </Typography>
          <Typography style={{ marginTop: "32px" }} variant="h5">
            Email :
            <span>
              <a href="mailto:sp.localusdt@gmail.com">
                {" "}
                sp.localusdt@gmail.com
              </a>
            </span>
          </Typography>
          {/* <Typography style={{ marginTop: "32px" }} variant="h5">
            Facebook:{" "}
            <a href="https://web.facebook.com/GomarketsCambodia">
              {" "}
              GomarketsCambodia
            </a>{" "}
          </Typography>
          <Typography style={{ marginTop: "32px" }} variant="h5">
            Telegram:
            <a href="https://t.me/gomarketscam"> gomarketscam</a>{" "}
          </Typography> */}
        </Box>
      </div>
    </div>
  );
}

export default Contact;
