// MyContext.js
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { API_ENDPOINT } from "../../contants";

const CryptoContext = React.createContext();

const CryptoContextProvider = ({ children }) => {
  const [exchangeRates, setExchangeRates] = useState({});
  const [walletInfo, setWalletInfo] = useState([]);
  const [bankAccountInfo, setBankAccountInfo] = useState([]);
  const [extendCost, setExtendCost] = useState([]);
  const [usdtPrice, setUsdtPrice] = useState(0);
  const [loading, setLoading] = useState(true);
  const fetchExchangeRate = async () => {
    try {
      const res = await axios.get(`${API_ENDPOINT}/currency-exchange`);
      if (res.data) {
        setExchangeRates(res.data.data[0]);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchWalletInfo = async () => {
    try {
      const res = await axios.get(`${API_ENDPOINT}/wallet`);
      if (res.data) {
        setWalletInfo(res.data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchBankAccountInfo = async () => {
    try {
      const res = await axios.get(`${API_ENDPOINT}/bank-account`);
      if (res.data) {
        setBankAccountInfo(res.data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getUSDTPrice = async () => {
    try {
      const response = await axios.get(
        "https://api.coincap.io/v2/assets/tether"
      );
      const usdtData = response.data.data;
      setUsdtPrice(usdtData);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getExtendCost = async () => {
    try {
      const res = await axios.get(`${API_ENDPOINT}/cost`);

      const cost = res.data.data;
      setExtendCost(cost);
    } catch (error) {
      console.log("error", error);
    }
  };

  const load = async () => {
    await Promise.all([
      fetchExchangeRate(),
      fetchWalletInfo(),
      fetchBankAccountInfo(),
      getUSDTPrice(),
      getExtendCost(),
    ]);
    setTimeout(async () => {
      await Promise.all([fetchExchangeRate(), getUSDTPrice()]);
    }, 1000 * 60 * 60);
    setLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <CryptoContext.Provider
      value={{
        loading,
        usdtPrice,
        exchangeRates,
        bankAccountInfo,
        walletInfo,
        extendCost,
      }}
    >
      {children}
    </CryptoContext.Provider>
  );
};

const useCryptoContext = () => {
  const contextValue = useContext(CryptoContext);

  return contextValue;
};
export { CryptoContext, useCryptoContext };

// useMyContext.js

export default CryptoContextProvider;
