import {
  Box,
  Button,
  Container,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import Carousel from "./Carousel";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  banner: {
    backgroundImage: "url(./banner2.jpg)",
  },
  bannerContent: {
    height: 400,
    display: "flex",
    flexDirection: "column",
    paddingTop: 25,
    justifyContent: "space-around",
  },
  tagline: {
    display: "flex",
    height: "40%",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  carousel: {
    height: "50%",
    display: "flex",
    alignItems: "center",
  },
}));

function Banner() {
  const history = useHistory();
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const onClickToBuy = () => {
    history.push({
      pathname: "/form",
      search: "?mode=buy",
    });
  };

  const onClickToSell = () => {
    history.push({
      pathname: "/form",
      search: "?mode=buyUSDT",
    });
  };

  const buttonProps = {
    // variant: isSmallScreen ? "text" : "outlined",
    size: isSmallScreen ? "small" : "large",
  };

  return (
    <div className={classes.banner}>
      <Container className={classes.bannerContent}>
        <div className={classes.tagline}>
          <Typography
            variant={isSmallScreen ? "h3" : "h2"}
            style={{
              fontWeight: "bold",
              marginBottom: 15,
              fontFamily: "Montserrat",
            }}
          >
            Crypto Hunter
          </Typography>
          <Typography
            variant="subtitle2"
            style={{
              color: "darkgrey",
              textTransform: "capitalize",
              fontFamily: "Montserrat",
            }}
          >
            Get all the Info regarding your favorite Crypto Currency
          </Typography>
          <Box
            display="flex"
            mt={3}
            justifyContent="center"
            sx={{ gap: "24px" }}
          >
            <Button
              {...buttonProps}
              variant="contained"
              color="primary"
              onClick={onClickToBuy}
            >
              BUY USDT
            </Button>
            <Button
              {...buttonProps}
              variant="contained"
              color="secondary"
              onClick={onClickToSell}
            >
              DEPOSIT BROKER
            </Button>
          </Box>
        </div>
        <Carousel />
      </Container>
    </div>
  );
}

export default Banner;
